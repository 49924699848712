<template>
  <form>
    <slot></slot>
    <div
      v-if="Object.keys(service.errors).length > 0"
      class="alert alert-danger mt-4"
      role="alert"
    >
      <ul class="mb-0">
        <li v-for="(error, index) of service.errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>
    <SpeedDial
      v-if="typeof actions !== 'undefined' || actions"
      :service="service"
      :actions="actions || ['save']"
      showIcon="pi pi-save"
      buttonClass="p-button-success text-light"
      :tooltipOptions="{ position: 'left' }"
      :onClick="this.onSave"
    >
    </SpeedDial>
    <Toast />
  </form>
</template>

<script>
import { useRouter } from "vue-router";
import SpeedDial from "primevue/speeddial";
import Toast from "primevue/toast";

export default {
  components: { SpeedDial, Toast, useRouter },
  props: {
    service: Object,
    actions: Array,
    isSuccessToast: Boolean,
    redirectAfterSave: String,
  },
  return: {
    requestResult: false,
  },
  methods: {
    async onSave() {
      if ((this.requestResult = await this.service.save())) {
        this.$toast.add({
          severity: "success",
          summary: "Успешно",
          detail: "Данные сохранены",
          life: 3000,
        });
        this.$router.push({
          name: this.redirectAfterSave,
          params: { id: this.requestResult.data.data.id },
        });
      } else {
        console.log("Ошибка");
      }
    },
    async sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>