import Service from '../../../services/Service';

class UserService extends Service {

    basePath = 'user';

    newObject() {
        return new UserService();
    }
    
}

export default UserService;