import Service from '../../../services/Service';

class SickLeaveService extends Service {

    basePath = 'sick-leave';

    newObject() {
        return new SickLeaveService();
    }
    
}

export default SickLeaveService;