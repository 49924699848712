<template>
  <div class="container-fluid">
    <div class="page-title-box">
      <bread-crumb
        :breadcrumbs="[
          { label: 'Больничные', routeName: 'SickLeave' },
          { label: 'Редактирование' },
        ]"
      />
      <h4 class="page-title">Редактирование</h4>
    </div>

    <div class="card">
      <div class="card-body">
        <Form
          :service="service"
          redirectAfterSave="SickLeaveShow"
          :actions="[
            { action: 'save', to: (data) => '/sick-leave/' + data.id },
          ]"
        >
          <FormField
            label="Сотрудник"
            :service="service"
            field="userId"
            :options="userList"
            type="select"
          />
          <FormField
            label="Начало отпуска"
            :service="service"
            field="dtStart"
            type="calendar"
          />
          <FormField
            label="Конец отпуска"
            :service="service"
            field="dtEnd"
            type="calendar"
          />
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/ui/BreadCrumb.vue";
import Form from "@/components/ui/form/Form.vue";
import FormField from "@/components/ui/form/FormField.vue";
import Str from "@/utils/Str";
import SickLeaveService from "../services/SickLeaveService";
import UserService from "@/modules/user/services/UserService";

export default {
  components: {
    BreadCrumb,
    Form,
    FormField,
  },
  data() {
    return {
      service: new SickLeaveService(),
      userService: new UserService(),
      userList: {},
      Str: Str,
    };
  },
  async created() {
    await this.service.show(this.$route.params.id);
    this.userList = await this.userService.allList();
  },
};
</script>
